<template>
    <div>
        <v-card flat height="100vh">
            <v-container>
                <div class="d-flex justify-center">
                    <div style="font-size: 40px" class="mt-6">
                        ملفك الشخصي
                    </div>
                    <div>
                        <v-img
                            src="../assets/img/profile.png"
                            alt="zaid"
                        ></v-img>
                    </div>
                </div>

                <v-list two-line>
                    <v-list-item>
                        <v-list-item-icon>
                            <v-icon color="indigo">
                                mdi-phone
                            </v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>
                                {{ userInfo.phoneNumber }}
                            </v-list-item-title>
                            <v-list-item-subtitle
                                >رقم الهاتف</v-list-item-subtitle
                            >
                        </v-list-item-content>

                        <v-list-item-icon>
                            <v-icon>mdi-message-text</v-icon>
                        </v-list-item-icon>
                    </v-list-item>

                    <v-divider inset></v-divider>
                    <v-list-item>
                        <v-list-item-icon>
                            <v-icon color="indigo">
                                mdi-email
                            </v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>
                                {{ userInfo.email }}
                            </v-list-item-title>
                            <v-list-item-subtitle
                                >البريد السخصي
                            </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-icon>
                            <v-icon>mdi-message-text</v-icon>
                        </v-list-item-icon>
                    </v-list-item>

                    <v-divider inset></v-divider>

                    <v-list-item>
                        <v-list-item-icon>
                            <v-icon color="indigo">
                                mdi-lock-reset
                            </v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>*************</v-list-item-title>
                            <v-list-item-subtitle>
                                <v-btn
                                    @click="dailogchangePassword = true"
                                    text
                                    class="mr-n4"
                                    color="primary"
                                >
                                    تغير كلمة السر
                                </v-btn>
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider inset></v-divider>
                </v-list>
            </v-container>

            <v-dialog v-model="dailogchangePassword" width="40%" persistent>
                <v-card flat>
                    <v-toolbar color="grey" dark>
                        <v-toolbar-title>
                            تغير كلمة المرور
                        </v-toolbar-title>
                    </v-toolbar>
                    <v-col>
                        <v-form
                            @submit.prevent="changePassword()"
                            ref="form"
                            v-model="valid"
                            lazy-validation
                        >
                            <v-row no-gutters class="mt-4">
                                <v-col cols="12">
                                    <v-text-field
                                        outlined
                                        dense
                                        color="secondary"
                                        filled
                                        v-model="body.oldPassword"
                                        label="كلمة المرور القديمة"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field
                                        v-model="body.password"
                                        outlined
                                        dense
                                        color="secondary"
                                        filled
                                        label="كلمة المرور الجديدة "
                                        :rules="[passwordFormat()]"
                                        :append-icon="
                                            showPassword
                                                ? 'mdi-eye'
                                                : 'mdi-eye-off'
                                        "
                                        :type="
                                            showPassword ? 'text' : 'password'
                                        "
                                        @click:append="
                                            showPassword = !showPassword
                                        "
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="12">
                                    <v-text-field
                                        outlined
                                        dense
                                        color="secondary"
                                        filled
                                        type="password"
                                        v-model="body.confirmPassword"
                                        :rules="[
                                            rules.required,
                                            rules.confirmPassword
                                        ]"
                                        label="تأكيد كلمة المرور"
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="12" md="12" class="mt-2">
                                    <v-btn
                                        type="submit"
                                        :disabled="
                                            !body.oldPassword ||
                                                !body.password ||
                                                !body.confirmPassword
                                        "
                                        :loading="loading"
                                        x-large
                                        color="primary"
                                    >
                                        حفظ
                                    </v-btn>
                                    <v-btn
                                        @click="close()"
                                        x-large
                                        color="error"
                                        class="mr-2"
                                        outlined
                                    >
                                        إلغاء
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-col>
                </v-card>
            </v-dialog>
        </v-card>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import validations from "@/utils/validations";

export default {
    data() {
        return {
            loading: false,
            valid: true,
            body: {},
            userInfo: {},
            dailogchangePassword: false,
            ...validations,
            showPassword: false,
            rules: {
                required: value => !!value || "Required.",
                confirmPassword: () =>
                    this.body.confirmPassword === this.body.password ||
                    "not match password"
            }
        };
    },
    created() {
        if (this.getUserDetails) this.getUserInfo();
    },

    computed: {
        ...mapGetters("auth", ["getUserDetails"])
    },
    methods: {
        getUserInfo() {
            this.$http
                .get("account/getUserById/" + this.getUserDetails.nameid)
                .then(response => {
                    this.userInfo = response.data.data;
                    console.log(this.userInfo);
                })
                .catch(e => {
                    console.log(e);
                });
        },

        changePassword() {
            this.loading = true;
            this.$http
                .post("account/changePasswordByUser", this.body)
                .then(response => {
                    this.close();
                    this.logOut();
                    this.$store.dispatch("setSnackbar", {
                        text: `تم تغير كلمةالمرور بنجاح`
                    });
                })
                .catch(err => {
                    this.$store.dispatch("setSnackbar", {
                        text: ` ${err.data.message}`,
                        color: "error"
                    });
                })
                .finally(() => (this.loading = false));
        },

        logOut() {
            this.$store.dispatch("auth/logout");
            this.$router.push("/login");
        },

        close() {
            this.loading = false;
            this.dailogchangePassword = false;
            this.body = {};
            this.resetValidation();
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        }
    }
};
</script>
<style lang=""></style>
